import React, {useEffect} from "react";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Container, Grid, Paper, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    TextField,
    Typography
} from "@mui/material";
import {addStudent, getStudents} from "../../firebase";
import {Link} from "react-router-dom";

function Students() {
    const [students, setStudents] = React.useState([]);
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");

    const isMounted = React.useRef(true);

    const addStudentHandler = async () => {
        const newStudent = {
            name,
            phone
        };
        await addStudent(newStudent);
        await fetchData();

        setName("");
        setPhone("");
    }

    const fetchData = async () => {
        const students = await getStudents();
        setStudents(students);
    }

    useEffect( () => {
        if (isMounted.current) {
            fetchData();
            isMounted.current = false;
        }
    }, []);

    return (
       <Box sx={{ display: 'flex' }}>
           <Grid container spacing={2}>
               <Grid item xs={12}>
                   <Card>
                      <CardContent sx={{ display: "flex",  flexDirection: "column", gap: "10px"}}>
                         <Typography variant="h5" component="h5">Add Student</Typography>
                          <TextField required id="name" value={name} label="Name" variant="outlined" onChange={(event) => { setName(event.target.value) }}/>
                          <TextField required id="phone" value={phone} label="Phone" variant="outlined" onChange={(event) => { setPhone(event.target.value) }} />
                      </CardContent>
                       <CardActions>
                           <Button size="small" color="primary" onClick={addStudentHandler}>Add</Button>
                       </CardActions>
                   </Card>
               </Grid>

               <Grid item xs={12}>
                   <TableContainer component={Paper}>
                       <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                           <TableBody>
                               {students.map((student) => (
                                     <TableRow key={student.id}>
                                            <TableCell component="th" scope="row">
                                                {student.name}
                                            </TableCell>
                                            <TableCell>{student.phone}</TableCell>
                                            <TableCell>
                                                <Link className="actions" to={"/dashboard/students/" + student.id}><Button size="small" color="primary">Show</Button></Link>
                                            </TableCell>
                                        </TableRow>
                                ))}
                           </TableBody>
                       </Table>
                   </TableContainer>
               </Grid>
           </Grid>
       </Box>
    )
}

export default Students;