import React, {useEffect} from "react";
import {getLessonsTeacher} from "../../firebase";
import {Box, Button} from "@mui/material";
import LessonList from "./Teacher/LessonList";

function Home() {
    const [lessons, setLessons] = React.useState([]);
    const shouldFetch = React.useRef(true);

    const fetchLessons = async () => {
        const data = await getLessonsTeacher();
        setLessons(data);
        return data
    }

    useEffect(() => {
        const fetchData = async () => {
            if (shouldFetch.current) {
                await fetchLessons();
                shouldFetch.current = false;
            }
        }
        fetchData();
    }, []);

    return (
        <Box sx={{ display: 'flex', gap: "10px", flexWrap: "wrap"}}>
            <Button variant="contained" color="primary" onClick={fetchLessons}>Refresh</Button>
            <LessonList lessons={lessons} />
        </Box>
    )
}

export default Home;