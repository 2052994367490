import React from "react";
import LessonItem from "./Lessonitem";

const LessonList = ({ lessons, student, afterChange }) => {

    const lessonFragment = (lesson) => <LessonItem key={lesson.id} lesson={lesson} student={student} afterChange={afterChange}/>;

    return (
        <>
            {lessons.map(lessonFragment)}
        </>
    )
}

export default LessonList;