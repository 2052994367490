import React from "react";
import {Button, Card, CardContent, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {setAsDone} from "../../../firebase";
import { atcb_action } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';

const LessonItem = ({ lesson }) => {
    const [isDone, setIsDone] = React.useState(false)


    const handleDone = async (lesson) => {
        await setAsDone(lesson.studentId, lesson.id)
        setIsDone(true)
    }

    const addToCalendar = () => {
        const date = `${lesson.lessonDate.getFullYear()}-${lesson.lessonDate.getMonth()+1}-${lesson.lessonDate.getDate()}`
        const hour = lesson.lessonDate.getHours()
        const minutes = lesson.lessonDate.getMinutes()

        atcb_action({
            name: `${lesson.studentName} - ${lesson.lessonTitle}`,
            startDate: date,
            startTime: `${hour}:${minutes}`,
            endDate: date,
            endTime: `${hour+1}:${minutes}`,
            options: ['Apple', 'Google'],
            timeZone: "Europe/Berlin",
            iCalFileName: "Reminder-Event",
        });
    }

    return (
        <Card key={lesson.id} sx={{width: "200px"}}>
            <CardContent>
                { isDone ? <Typography component="p" color="red">Done</Typography> : null }
                <Link className="actions" to={"/dashboard/students/" + lesson.studentId}><Typography variant="h5" component="h5">{lesson.studentName}</Typography></Link>
                <Typography variant="h6" component="h6">{lesson.lessonTitle}</Typography>
                <Typography variant="body2" component="p">{lesson.lessonDate.toLocaleString()}</Typography>
                <Button onClick={addToCalendar}>Add to calendar</Button>

                <Button variant="outlined" onClick={() => handleDone(lesson)}>Done</Button>
            </CardContent>
        </Card>
    )

}

const LessonList = ({ lessons }) => {

    return (
        <>
            {lessons.map(lesson => <LessonItem lesson={lesson} /> )}
        </>
    )
}

export default LessonList;