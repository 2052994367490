import React from 'react'
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, MenuItem, Select,
    TextField,
    Typography
} from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {addLesson, addLessonResource, getLessonsTemplate, getLessonTemplateResources} from "../../../firebase";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";

const LessonAdd = ({ student, afterSave }) => {
    const [title, setTitle] = React.useState("");
    const [date, setDate] = React.useState(null);
    const [templates, setTemplates] = React.useState([])
    const [template, setTemplate] = React.useState("")

    const [openManual, setOpenManual] = React.useState(false)
    const [openTemplate, setOpenTemplate] = React.useState(false)

    const addLessonHandler = async () => {
        if (!(title && date)) { return }
        const lesson = {
            title,
            isDone: false,
            date: date.toDate()
        }

        await addLesson(student.id, lesson);
        afterSave();
        setTitle("");
        setDate(null);
    }

    const handleClickOpenManual = () => {
        setOpenManual(true)
    }

    const handleClickOpenTemplate = async () => {
        const data = await getLessonsTemplate()
        setTemplates(data)
        setOpenTemplate(true)
    }

    const handleClose = () => {
        setTitle("")
        setDate(null)
        setOpenManual(false)
    }

    const handleCloseTemplate = () => {
        setTemplate("")
        setDate(null)
        setOpenTemplate(false)
    }

    const handleChange = (event) => {
        setTemplate(event.target.value)
    }

    const handleAddFromTemplate = async () => {
        const lesson = templates.filter(item => item.id === template).pop()

        const newLesson = {
            title: lesson.title,
            isDone: false,
            date: date.toDate()
        }

        const sLesson = await addLesson(student.id, newLesson)
        const resources = await getLessonTemplateResources(lesson.id)

        resources.map(async r => {
           await addLessonResource(student.id, sLesson.id, { type: r.type, url: r.url, name: r.name })
        })

        afterSave();

        setTemplate("")
        setDate(null)
        setOpenTemplate(false)
    }

return (
       <Card>
           <CardContent sx={{ display: "flex",  flexDirection: "column", gap: "10px"}}>
               <Typography variant="h5" component="h5">Add Lesson</Typography>
               <Button variant="outlined" onClick={handleClickOpenManual}>Add manual</Button>
               <Button variant="outlined" onClick={handleClickOpenTemplate}>Add from template</Button>

               <Dialog open={openManual} onClose={handleClose}>
                   <DialogTitle>Add lesson manual</DialogTitle>
                   <DialogContent sx={{ display: "flex",  flexDirection: "column", gap: "10px"}}>
                       <TextField required id="title" value={title} label="Title" variant="outlined" onChange={(event) => { setTitle(event.target.value) }}/>
                       <LocalizationProvider dateAdapter={AdapterMoment}>
                       <DateTimePicker
                           renderInput={(props) => <TextField required {...props} />}
                           label="Date"
                           value={date}
                           onChange={(newValue) => {
                               setDate(newValue);
                           }}
                       />
                       </LocalizationProvider>
                   </DialogContent>
                   <DialogActions>
                       <Button size="small" color="primary" onClick={addLessonHandler}>Add</Button>
                       <Button size="small" onClick={handleClose}>Cancel</Button>
                   </DialogActions>
               </Dialog>

               <Dialog open={openTemplate} onClose={handleCloseTemplate}>
                   <DialogTitle>Add lesson manual</DialogTitle>
                   <DialogContent sx={{ display: "flex",  flexDirection: "column", gap: "10px"}}>
                       <Select
                           value={template}
                           label="Lesson"
                           onChange={handleChange}
                       >
                           {templates.map(t => (
                               <MenuItem key={t.id} value={t.id}>{t.title}</MenuItem>
                           ))}
                       </Select>
                       <LocalizationProvider dateAdapter={AdapterMoment}>
                       <DateTimePicker
                           renderInput={(props) => <TextField required {...props} />}
                           label="Date"
                           value={date}
                           onChange={(newValue) => {
                               setDate(newValue);
                           }}
                       />
                       </LocalizationProvider>

                   </DialogContent>
                   <DialogActions>
                       <Button size="small" color="primary" onClick={handleAddFromTemplate}>Add</Button>
                       <Button size="small" onClick={handleCloseTemplate}>Cancel</Button>
                   </DialogActions>
               </Dialog>

           </CardContent>
       </Card>
    )
}

export default LessonAdd;