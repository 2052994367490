import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {TextField, Typography} from "@mui/material";
import {addLessonResource, uploadResource} from "../../../firebase";

const LessonResourceAdd = ({ lesson, student, afterUpload }) => {
    const [open, setOpen] = React.useState(false);
    const [link, setLink] = React.useState("");
    const [name, setName] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setLink("");
        setName("");
        setOpen(false);
    };

    const handleAdd = async () => {
        if (link.length > 0 && name.length > 0) {
            await addLessonResource(student.id, lesson.id, { type: 'link', url: link, name: name });
            afterUpload();
            handleClose();
        } else {
            alert("Please fill in all fields");
        }
    }

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                Add link
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Upload</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '5px'}}>
                    <TextField onChange={(event) => { setName(event.target.value)}} value={name} label="Name" fullWidth />
                    <TextField onChange={(event) => { setLink(event.target.value)}} value={link} label="Link" fullWidth />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAdd}>Add</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default LessonResourceAdd;