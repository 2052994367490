import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Typography} from "@mui/material";
import { getDownloadURL } from 'firebase/storage'
import {addLessonTemplateResource, uploadResource} from "../../../../firebase";

const LessonResourceAddFile = ({ lesson, afterUpload }) => {
    const [open, setOpen] = React.useState(false);
    const [file, setFile] = React.useState("");
    const [percentage, setPercentage] = React.useState(0);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setFile("");
        setPercentage(0);
        setOpen(false);
    };

    const handleChange = (event) => {
        setFile(event.target.files[0]);
    }

    const handleUpload = () => {
        if (file.length == 0) {
            alert("Please select a file");
            return;
        }
        const uploadTask = uploadResource(lesson.title, file);
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setPercentage(progress);
            },
            (error) => {
                console.log(error);
            },
            async () => {
                const url = await getDownloadURL(uploadTask.snapshot.ref)
                await addLessonTemplateResource(lesson.id, { type: 'file', url, name: file.name });
                await afterUpload();
                handleClose();
            }
        )
    }

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                Add file
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Upload</DialogTitle>
                <DialogContent>
                    <input type="file" onChange={handleChange} accept="" />

                    <Typography>{percentage}% done</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleUpload}>Upload</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default LessonResourceAddFile;