import React from 'react'
import {Button, Card, CardActions, CardContent, TextField, Typography} from "@mui/material";
import {addLessonTemplate} from "../../../../firebase";

const LessonAdd = ({ afterSave }) => {
    const [title, setTitle] = React.useState("");

    const addLessonHandler = async () => {
        if (!title) { return }
        const lesson = {
            title,
        }
        await addLessonTemplate(lesson);
        afterSave();
        setTitle("");
    }

    return (
        <Card>
            <CardContent sx={{ display: "flex",  flexDirection: "column", gap: "10px"}}>
                <Typography variant="h5" component="h5">Add Lesson</Typography>
                <TextField required id="title" value={title} label="Title" variant="outlined" onChange={(event) => { setTitle(event.target.value) }}/>
            </CardContent>
            <CardActions>
                <Button size="small" color="primary" onClick={addLessonHandler}>Add</Button>
            </CardActions>
        </Card>
    )
}

export default LessonAdd;