import React, {useEffect} from "react";
import {Box, Card, CardContent, List, ListItem, ListItemText, Typography} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LinkIcon from '@mui/icons-material/Link';
import Button from "@mui/material/Button";
import LessonResourceAddFile from "./LessonResourceAddFile";
import LessonResourceAddLink from "./LessonResourceAddLink";
import {getLessonTemplateResources} from "../../../../firebase";

const LessonItem = ({lesson, afterChange}) => {
    const [resources, setResources] = React.useState([]);
    const [showResource, setShowResource] = React.useState(false);

    const fetchResources = async (lesson) => {
        const data = await getLessonTemplateResources(lesson.id);
        setResources(data);
        return data
    }

    useEffect(() => {
        const fetchData = async () => {
            if (showResource) {
                await fetchResources(lesson)
            }
        }
        fetchData();
    }, [showResource])

    return <Card>
        <CardContent>
            <Typography variant="h5" component="h5">{lesson.title}</Typography>
            { showResource ?
                <>
                    {resources.length > 0 ?
                        <List>
                            {resources.map(resource => <ListItem key={resource.id}>
                                <ListItemText secondary={
                                    <>
                                        <a className="actions inlineIcon" href={resource.url} target="_blank">
                                            {resource.type == 'file' ? <FileDownloadIcon/> : <LinkIcon/>}
                                            <Typography className="shortText"
                                                        variant="body2">{resource.name}</Typography>
                                        </a>
                                    </>
                                }/>
                            </ListItem>)}
                        </List>
                        : <Typography variant="body2" component="p">No resources</Typography>
                    }
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px'}}>
                        <LessonResourceAddFile lesson={lesson} afterUpload={() => fetchResources(lesson)}/>
                        <LessonResourceAddLink lesson={lesson} afterUpload={() => fetchResources(lesson)}/>
                    </Box>
                </>
                : <Button onClick={() => setShowResource(true)}>Show Resources</Button>}
        </CardContent>
    </Card>;
}

export default LessonItem;