import React from 'react'
import {Button, Card, CardActions, CardContent, TextField, Typography} from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {addLesson, updateLesson} from "../../../firebase";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import EditIcon from '@mui/icons-material/Edit';

const LessonEdit = ({ student, afterSave, lesson }) => {
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState(lesson.title);
    const [date, setDate] = React.useState(lesson.date);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setTitle("");
        setDate(null);
        setOpen(false);
    };

    const handleUpdate = async () => {
        if (!(title && date)) { return }
        const newLesson = {
            title,
            date: date.toDate()
        }

        await updateLesson(student.id, lesson.id, newLesson);
        afterSave();
        handleClose()
    }

    return (
        <div>
            <Button className="actions" onClick={handleClickOpen}>
                <Typography variant="body2" component="p">{lesson.date.toLocaleString()} <EditIcon sx={{ fontSize: 15 }} /></Typography>
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit lesson</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '5px'}}>
                    <TextField required id="title" value={title} label="Title" variant="outlined" onChange={(event) => { setTitle(event.target.value) }}/>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                            renderInput={(props) => <TextField required {...props} />}
                            label="Date"
                            value={date}
                            onChange={(newValue) => {
                                setDate(newValue);
                            }}
                        />
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleUpdate}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>


    )
}

export default LessonEdit;