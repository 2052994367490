import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import useAuth from '../Hooks/useAuth';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CookieConsent from "react-cookie-consent";

import {
    Alert,
    Avatar,
    Box,
    Button,
    Collapse,
    CssBaseline,
    Divider,
    Grid, IconButton,
    Paper,
    TextField,
    Typography
} from "@mui/material";

function Login() {
    const navigate = useNavigate();
    const { googleLogin, facebookLogin, authed, registerStudent, emailLogin, role } = useAuth();

    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [error, setError] = React.useState(false)
    const [alert, setAlert] = React.useState(false)
    const [registerSuccess, setRegisterSuccess] = React.useState(false)

    const handleLoginGoogle = async () => {
        await googleLogin()
    }

    const redirectAfterLogin = (role) => {
        if (role === 'admin') {
            navigate("/dashboard");
        } else if (role === 'student') {
            navigate("/student");
        } else {
            setAlert(true)
        }
    }

    const handleLoginFacebook = async () => {
        await facebookLogin()
    }

    const handleLoginEmail = () => {
        if (email === "" || password === "") {
            setError(true)
        }

        emailLogin(email, password).catch(() => {
            setError(true)
        })
    }

    const handleRegister = () => {
        if (email === "" || password === "") {
            setError(true)
        }

        registerStudent(email, password).then(() => {
            setEmail("")
            setPassword("")
            setRegisterSuccess(true)
        })
    }

    useEffect(() => {
        if (role) {
            redirectAfterLogin(role)
        }
    }, [role])

    useEffect(() => {
        if (authed) {
            redirectAfterLogin(role)
        }
    }, [authed]);

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid
                item
                xs={12}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: 'url(https://source.unsplash.com/H6clJErbJ4E)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Collapse in={error}>
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setError(false);
                                }}>
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        }
                    >Please check email or password!</Alert>
                </Collapse>
                <Collapse in={alert}>
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setAlert(false);
                                }}>
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        }
                    >Please contact with administrator!</Alert>
                </Collapse>
                <Collapse in={registerSuccess}>
                    <Alert severity="success">Registration success!</Alert>
                </Collapse>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h4">
                        English Easy Going
                    </Typography>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box sx={{ mt: 1, display: "flex", flexDirection: "column", gap: "5px" }}>
                        <TextField id="email" label="Enter the Email" variant="outlined" onChange={e => setEmail(e.target.value)} />
                        <TextField id="password" type="password" label="Enter the Password" variant="outlined" onChange={e => setPassword(e.target.value)} />
                        <Box sx={{ display: "flex", flexDirection: "row", gap: "5px", justifyContent: "center"}}>
                            <Button variant="outlined" onClick={handleLoginEmail}>Login</Button>
                            <Button variant="outlined" color="secondary" onClick={handleRegister}>Register</Button>
                        </Box>

                        <Divider />

                        <Button className="loginButton" variant="outlined" color="error" onClick={handleLoginGoogle}>
                            <GoogleIcon /> Login with Google
                        </Button>

                        <Button className="loginButton" variant="outlined" color="primary" onClick={handleLoginFacebook}>
                            <FacebookIcon /> Login with Facebook
                        </Button>
                    </Box>
                </Box>
            </Grid>
            <CookieConsent>This site uses cookies.</CookieConsent>
        </Grid>
    )
}

export default Login;