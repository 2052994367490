const firebaseConfig = {
    apiKey: 'AIzaSyCAWg1VOIhUDpfRw0bDjLQsLDj8UWJcqZw',
    authDomain: 'englisheasygoingdev.firebaseapp.com',
    databaseURL: 'https://englisheasygoingdev-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'englisheasygoingdev',
    storageBucket: 'englisheasygoingdev.appspot.com',
    messagingSenderId: '785088601632',
    appId: '1:785088601632:web:783b3a7ea3372b0a132645'
};

export default firebaseConfig;