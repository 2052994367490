import React from "react";
import LessonItem from "./LessonItem";
import {Box} from "@mui/material";

const LessonList = ({ lessons, student, toCalendar }) => {

    const lessonFragment = (lesson) => <LessonItem key={lesson.id} lesson={lesson} student={student} toCalendar={toCalendar} />;

    return (
        <Box sx={{ display: "flex", gap: "10px"}}>
            {lessons.map(lessonFragment)}
        </Box>
    )
}

export default LessonList;