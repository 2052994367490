import React, {useEffect} from "react";

import {
    AppBar, Box,
    Button, Container,
    CssBaseline, FormControl,
    Grid, MenuItem, Select, Tabs, Typography,
} from "@mui/material";
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import {getLessonCurrent, getStudentById, getStudentsId} from "../firebase";
import {TabContext, TabList} from "@mui/lab";
import LessonList from "./StudentDashboard/LessonList";
import useAuth from "../Hooks/useAuth";
import {useNavigate} from "react-router-dom";

function StudentDashboard() {
    const [students, setStudents] = React.useState([])
    const [selected, setSelected] = React.useState(null)
    const [tab, setTab] = React.useState('current')
    const [lessons, setLessons] = React.useState([])

    const { logout } = useAuth()
    const navigate = useNavigate();

    const handleLogout = () => {
        logout().then(() => {
            navigate("/");
        })
    }

    const fetchStudents = async () => {
        const ids = await getStudentsId()
        const data = await Promise.all(ids.map(id => getStudentById(id)))

        setStudents(data)
        if (data.length > 0) {
            setSelected(data[0])
        }

    }

    const handleChangeStudent = (event) => {
        const found = students.find(student => student.id == event.target.value )
        if (found) {
            setSelected(found)
        }
    }

    const handleChangeTab = (event, newValue) => {
        setTab(newValue)
    }

    useEffect(() => {
       fetchStudents()
    }, [])

    const fetchLesson = async () => {
        if (selected) {
            const isDone = tab == 'current' ? false : true
            const less = await getLessonCurrent(selected.id, isDone)
            setLessons(less)
        }
    }

    useEffect(() => {
        fetchLesson()
    }, [selected])

    useEffect(() => {
        fetchLesson()
    }, [tab])

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <AppBar position="absolute" sx={{ padding: "5px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                {students.length == 0 ? <Typography component="p" sx={{ padding: "5px"}}>No Access</Typography> :
                    students.length == 1 ?
                            <Typography component="p" sx={{ padding: "5px"}}>{selected.name}</Typography>
                            :
                            <FormControl variant="standard">
                                <Select
                                    value={selected.id}
                                    onChange={handleChangeStudent}
                                    sx={{maxWidth: "50%", minWidth: "200px", color: "white"}}
                                >
                                    {students.map(s => <MenuItem value={s.id}>{s.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                }
                <Button color="primary" variant="standard" onClick={handleLogout}>Logout</Button>
            </AppBar>
            <Container maxWidth="lg" sx={{ mt: 8, mb: 4 }}>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                            <Tab label="Current" value="current" />
                            <Tab label="Done" value="old" />
                        </TabList>
                    </Box>
                    <TabPanel value="current"><LessonList lessons={lessons} student={selected} toCalendar /></TabPanel>
                    <TabPanel value="old"><LessonList lessons={lessons} student={selected} /></TabPanel>
                </TabContext>
            </Container>
        </Grid>
    )
}

export default StudentDashboard;