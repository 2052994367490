import React, {useEffect} from "react";
import {
    logout as authLogout,
    auth,
    signInWithGoogle,
    createUserEmail,
    signInWithEmail,
    signInWithFacebook
} from "../firebase";
import {useAuthState} from "react-firebase-hooks/auth";

const authContext = React.createContext();

function useAuth() {
    const [authed, setAuthed] = React.useState(false);
    const [user] = useAuthState(auth);
    const [role, setRole] = React.useState(null);

    useEffect(() => {
        if (user) {
            handleUser(user);
        } else {
            setAuthed(false);
            setRole(null);
        }
    }, [user]);

    const handleUser = async (user, res) => {
        if (!user) {
            throw new Error('Wrong login')
        }
        const idTokenResult = await user.getIdTokenResult()
        let role = null
        if (idTokenResult.claims.admin) {
            role = "admin";
        } else if (idTokenResult.claims.student) {
            role = "student";
        }

        setRole(role)
        setAuthed(true);

        if (res) {
            res(user, role);
        }
    }

    return {
        authed,
        user,
        role,
        googleLogin() {
            return new Promise((res, reject) => {
                signInWithGoogle().then(async user => {
                    try {
                        await handleUser(user, res)
                    } catch (e) {
                        await authLogout()
                        return reject(e)
                    }
                })
            })
        },
        facebookLogin() {
            return new Promise((res, reject) => {
                signInWithFacebook().then(async user => {
                    try {
                        await handleUser(user, res)
                    } catch (e) {
                        await authLogout()
                        return reject(e)
                    }
                })
            })
        },
        emailLogin(email, password) {
            return new Promise( (res, reject) => {
                signInWithEmail(email, password).then(async (user) => {
                    try {
                        await handleUser(user, res)
                    } catch (e) {
                        await authLogout()
                        return reject(e)
                    }
                })
            })
        },
        registerStudent(email, password) {
            return createUserEmail(email, password)
        },
        logout() {
            return new Promise((res) => {
                authLogout()
                setAuthed(false);
                setRole(null);
                res();
            })
        }
    }
}

export function AuthProvider({children}) {
    const auth = useAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    )
}

export default function AuthConsumer() {
    return React.useContext(authContext);
}