import { initializeApp } from "firebase/app";
import {
    FacebookAuthProvider,
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signOut,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
} from "firebase/auth";
import {
    getStorage,
    uploadBytesResumable,
    ref,
} from "firebase/storage"
import {
    getFirestore,
    query,
    getDocs,
    collection,
    addDoc,
    updateDoc,
    doc,
    getDoc,
    orderBy,
    Timestamp,
    setDoc,
    where
} from "firebase/firestore";
import firebaseConfig from "./firebaseConfig";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
    return auth.currentUser
  } catch (err) {
    console.log(err);
  }
}

const facebookProvider = new FacebookAuthProvider();
const signInWithFacebook = async () => {
    try {
        await signInWithPopup(auth, facebookProvider)
        return auth.currentUser
    } catch (err) {
        console.log(err)
    }
}

const logout = () => {
    signOut(auth);
}

const createUserEmail = async (email, password) => {
    try {
        return createUserWithEmailAndPassword(auth, email, password)
    } catch (err) {
        console.log(err)
    }
}

const signInWithEmail = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password)
        console.log(auth.currentUser)
        return auth.currentUser
    } catch (err) {
        console.log(err);
    }
}

// --------- COLLECTIONS ----------

const STUDENTS = 'students';

// --------- Students ---------


const studentsCollection = collection(db, STUDENTS);
const getStudents = async () => {
    try {
        const q = query(studentsCollection, orderBy('name'))
        const data = await getDocs(q)
        const students = data.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        return students;
    } catch (err) {
        console.log(err);
    }
}

const addStudent = async (student) => {
    try {
        const data = await addDoc(studentsCollection, student);
        return data;
    } catch (err) {
        console.log(err);
    }
}

const getStudentById = async (id) => {
    try {
        const docRef = doc(db, STUDENTS, id);
        const data = await getDoc(docRef);
        const student = { id: data.id, ...data.data() };
        return student;
    } catch (err) {
        console.log(err);
    }
}

const addLesson = async (studentId, lesson) => {
    const lessionsRef = collection(db, `${STUDENTS}/${studentId}/lessons`)
    try {
        const data = await addDoc(lessionsRef, lesson);
        return data;
    } catch (err) {
        console.log(err);
    }
}

const updateLesson = async (studentId, lessonId, lesson) => {
    const docRef = doc(db, `${STUDENTS}/${studentId}/lessons/${lessonId}`)
    try {
        const data = await setDoc(docRef, lesson);
        return data;
    } catch (err) {
        console.log(err);
    }
}

const addLessonResource = async (studentId, lessonId, resource) => {
    const resourcesRef = collection(db, `${STUDENTS}/${studentId}/lessons/${lessonId}/resources`)
    try {
        const data = await addDoc(resourcesRef, resource);
        return data;
    } catch (err) {
        console.log(err);
    }
}

const getLessons = async (studentId) => {
    const resourcesRef = collection(db, `${STUDENTS}/${studentId}/lessons/`)
    const q = query(resourcesRef, orderBy('date'))
    try {
        const data = await getDocs(q);
        const lessons = data.docs.map(doc => {
            const value = doc.data();
            const lesson = {
                id: doc.id,
                title: value.title,
                isDone: value.isDone || false,
                date: Timestamp.fromDate(value.date.toDate()).toDate()
            };
            return lesson;
        })
        return lessons;
    } catch (err) {
        console.log(err);
    }
}

const getLessonResources = async (studentId, lessonId) => {
    const resourcesRef = collection(db, `${STUDENTS}/${studentId}/lessons/${lessonId}/resources`)
    const q = query(resourcesRef)
    try {
        const data = await getDocs(q);
        const resources = data.docs.map(doc => {
            const value = doc.data();
            const resource = {
                id: doc.id,
                type: value.type,
                url: value.url,
                name: value.name
            };
            return resource;
        })
        return resources;
    } catch (err) {
        console.log(err);
    }
}

const getLessonsTeacher = async () => {
    const resourcesRef = collection(db, 'lessonCalendar')
    const q = query(resourcesRef, orderBy('lessonDate'))
    try {
        const data = await getDocs(q);
        const lessons = data.docs.map(doc => {
            const value = doc.data();
            const lesson = {
                id: doc.id,
                studentName: value.studentName,
                studentId: value.studentId,
                lessonTitle: value.lessonTitle,
                lessonDate: Timestamp.fromDate(value.lessonDate.toDate()).toDate()
            };
            return lesson;
        })
        return lessons;
    } catch (err) {
        console.log(err);
    }
}

const uploadResource = (folder, file) => {
    const storageRef = ref(storage, `${folder}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    return uploadTask;
}

const getLessonsTemplate = async () => {
    const resourcesRef = collection(db, 'lessons')
    const q = query(resourcesRef)
    try {
        const data = await getDocs(q);
        const lessons = data.docs.map(doc => {
            const value = doc.data();
            const lesson = {
                id: doc.id,
                title: value.title,
            };
            return lesson;
        })
        return lessons;
    } catch (err) {
        console.log(err);
    }
}

const addLessonTemplate = async (lesson) => {
    const lessionsRef = collection(db, `lessons`)
    try {
        const data = await addDoc(lessionsRef, lesson);
        return data;
    } catch (err) {
        console.log(err);
    }
}

const addLessonTemplateResource = async (lessonId, resource) => {
    const resourcesRef = collection(db, `/lessons/${lessonId}/resources`)
    try {
        const data = await addDoc(resourcesRef, resource);
        return data;
    } catch (err) {
        console.log(err);
    }
}

const getLessonTemplateResources = async (lessonId) => {
    const resourcesRef = collection(db, `/lessons/${lessonId}/resources`)
    const q = query(resourcesRef)
    try {
        const data = await getDocs(q);
        const resources = data.docs.map(doc => {
            const value = doc.data();
            const resource = {
                id: doc.id,
                type: value.type,
                url: value.url,
                name: value.name
            };
            return resource;
        })
        return resources;
    } catch (err) {
        console.log(err);
    }
}

const setAsDone = async (studentId, lessonId) => {
    const docRef = doc(db, `${STUDENTS}/${studentId}/lessons/${lessonId}`)
    try {
       await updateDoc(docRef, { isDone: true })
    } catch (err) {
        console.log(err);
    }
}

// ------------- Student dashboard

const getStudentsId = async () => {
    const authRef = doc(db, `auth/${auth.currentUser.uid}`)
    try {
        const data = await getDoc(authRef)
        return data.data().students || []
    } catch (err) {
        console.log(err)
    }
}

const getLessonCurrent = async (studentId, isDone) => {
    const resourcesRef = collection(db, `${STUDENTS}/${studentId}/lessons/`)
    const q = query(resourcesRef, where("isDone", '==', isDone), orderBy('date'))
    try {
        const data = await getDocs(q);
        const lessons = data.docs.map(doc => {
            const value = doc.data();
            const lesson = {
                id: doc.id,
                title: value.title,
                isDone: value.isDone,
                date: Timestamp.fromDate(value.date.toDate()).toDate()
            };
            return lesson;
        })
        return lessons;
    } catch (err) {
        console.log(err);
    }
}

export {
    auth,
    db,
    signInWithGoogle,
    signInWithFacebook,
    logout,
    getStudents,
    addStudent,
    getStudentById,
    addLesson,
    getLessons,
    getLessonsTeacher,
    uploadResource,
    addLessonResource,
    getLessonResources,
    updateLesson,
    getLessonsTemplate,
    addLessonTemplateResource,
    getLessonTemplateResources,
    addLessonTemplate,
    setAsDone,
    createUserEmail,
    signInWithEmail,
    getStudentsId,
    getLessonCurrent
}