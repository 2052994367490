import React from 'react';
import LessonItem from './LessonItem'

const LessonList = ({ lessons, afterChange }) => {

        const lessonFragment = (lesson) => <LessonItem key={lesson.id} lesson={lesson} afterChange={afterChange}/>;

        return (
            <>
                {lessons.map(lessonFragment)}
            </>
        )
}

export default LessonList;