import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {getLessons, getStudentById} from "../../../firebase";
import {Box, Card, CardContent, Typography} from "@mui/material";
import LessonAdd from "./LessonAdd";
import LessonList from "./LessonList";

function Student() {
    const [student, setStudent] = React.useState(null);
    const [lessons, setLessons] = React.useState([]);
    const shouldFetch = React.useRef(true);
    let { id } = useParams();

    const fetchLessons = async (student) => {
        const data = await getLessons(student.id);
        setLessons(data);
        return data
    }

    const fetchStudent = async () => {
        const data = await getStudentById(id);
        setStudent(data);
        return data;
    }

    useEffect(() => {
        const fetchData = async () => {
            if (shouldFetch.current) {
                const student = await fetchStudent();
                const lessons = await fetchLessons(student)
                shouldFetch.current = false;
            }
        }
        fetchData();
    }, [])

    const loadingFragment = <Typography variant="h5" component="h5">Loading...</Typography>;
    const studentFragment = (student) => (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="h5">{student.name}</Typography>
                    <Typography variant="body2" component="p">{student.phone}</Typography>
                </CardContent>
            </Card>
            <LessonAdd student={student} afterSave={() => fetchLessons(student)} />
            <LessonList lessons={lessons} student={student} afterChange={() => fetchLessons(student)} />
        </>
    );

    return (
        <Box sx={{ display: 'flex', gap: "10px", flexDirection: "row" }}>
            {student ? studentFragment(student) : loadingFragment}
        </Box>
    )
}

export default Student;