import React, {useEffect} from "react";
import {Box, Card, CardContent, List, ListItem, ListItemText, Typography} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LinkIcon from '@mui/icons-material/Link';
import Button from "@mui/material/Button";
import {getLessonResources} from "../../firebase";
import { atcb_action } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';

const LessonItem = ({lesson, student, toCalendar}) => {
    const [resources, setResources] = React.useState([]);
    const [showResource, setShowResource] = React.useState(false);

    const fetchResources = async (lesson) => {
        const data = await getLessonResources(student.id, lesson.id);
        setResources(data);
        return data
    }

    useEffect(() => {
        const fetchData = async () => {
            if (showResource) {
                await fetchResources(lesson)
            }
        }
        fetchData();
    }, [showResource])

    const addToCalendar = () => {
        const date = `${lesson.date.getFullYear()}-${lesson.date.getMonth()+1}-${lesson.date.getDate()}`
        let hour = lesson.date.getHours()
        let minutes = lesson.date.getMinutes().toString()

        let endHour = hour + 1

        if (hour < 10) {
            hour = `0${hour}`
        }

        if (endHour < 10) {
            endHour = `0${endHour}`
        }

        if (minutes < 10) {
            minutes = `0${minutes}`
        }

        atcb_action({
            name: `${lesson.title}`,
            startDate: date,
            startTime: `${hour}:${minutes}`,
            endDate: date,
            endTime: `${endHour}:${minutes}`,
            options: ['Apple', 'Google'],
            timeZone: "Europe/Berlin",
            iCalFileName: "Reminder-Event",
        });
    }

    return <Card>
        <CardContent sx={{ display: "flex", flexDirection: "column"}}>
            { lesson.isDone ? <Typography component="p" color="red">Done</Typography> : null }
            <Typography variant="h5" component="h5">{lesson.title}</Typography>
            <Typography variant="body2" component="p">{lesson.date.toLocaleString()}</Typography>
            { toCalendar ? <Button onClick={addToCalendar}>Add to calendar</Button> : null }
            { showResource ?
                <>
                    {resources.length > 0 ?
                        <List>
                            {resources.map(resource => <ListItem key={resource.id}>
                                <ListItemText secondary={
                                    <>
                                        <a className="actions inlineIcon" href={resource.url} target="_blank">
                                            {resource.type == 'file' ? <FileDownloadIcon/> : <LinkIcon/>}
                                            <Typography className="shortText"
                                                        variant="body2">{resource.name}</Typography>
                                        </a>
                                    </>
                                }/>
                            </ListItem>)}
                        </List>
                        : <Typography variant="body2" component="p">No resources</Typography>
                    }
                </>
                : <Button onClick={() => setShowResource(true)}>Show Resources</Button>}
        </CardContent>
    </Card>;
}

export default LessonItem;