import React, {useEffect} from "react";
import LessonList from "./Teacher/Lessons/LessonList";
import {Box, Typography} from "@mui/material";
import LessonAdd from "./Teacher/Lessons/LessonAdd";
import {getLessonsTemplate} from "../../firebase";

function Lessons() {
    const [lessons, setLessons] = React.useState([]);
    const shouldFetch = React.useRef(true);

    const fetchLessons = async () => {
        const data = await getLessonsTemplate()
        setLessons(data);
        return data
    }

    useEffect(() => {
        const fetchData = async () => {
            if (shouldFetch.current) {
                await fetchLessons()
                shouldFetch.current = false;
            }
        }
        fetchData();
    }, [])

    const loadingFragment = <Typography variant="h5" component="h5">Loading...</Typography>;
    const lessonsFragment = (lessons) => (
        <>
            <LessonAdd afterSave={() => fetchLessons()} />
            <LessonList lessons={lessons} afterChange={() => fetchLessons(lessons)} />
        </>
    )

    return (
        <Box sx={{ display: 'flex', gap: "10px", flexDirection: "row" }}>
            {lessons ? lessonsFragment(lessons) : loadingFragment}
        </Box>
    )
}

export default Lessons;