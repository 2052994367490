import './App.css';
import React, {useEffect, useState} from 'react';
import {Route, BrowserRouter as Router, Routes, Navigate, Outlet, useLocation} from "react-router-dom";
import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard";
import useAuth, {AuthProvider} from "./Hooks/useAuth";
import Home from "./Components/Dashboard/Home";
import Students from "./Components/Dashboard/Students";
import Lessons from "./Components/Dashboard/Lessons";
import Student from "./Components/Dashboard/Student/Student";
import StudentDashboard from "./Components/StudentDashboard";

function RequireAuth({children}) {
    const { authed } = useAuth()
    const location = useLocation()

    return authed === true ? (
        children
    ): (
        <Navigate to="/login" replace state={{ path: location.pathname }} />
    )
}


function App() {
  return (
      <AuthProvider>
        <div className="App">
            <Router>
                <Routes>
                    <Route index element={<Navigate to="/login" replace />} />
                    <Route path={'/login'} element={<Login />} />
                    <Route path={'/student'} element={<RequireAuth><StudentDashboard /></RequireAuth>} />
                    <Route path="dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} >
                        <Route index element={<Home />} />
                        <Route path="home" element={<Home />} />
                        <Route exact path="students" element={<Students />} />
                        <Route path="students/:id" element={<Student />} />
                        <Route path="lessons" element={<Lessons />} />
                    </Route>
                </Routes>
            </Router>
        </div>
      </AuthProvider>
  );
}

export default App;
